export default {
  appointments: {
    appointments: []
  },
  guestForm: {
    language: '',
    client_phone: '',
    booking_number: '',
    datetime: '',
    facility: ''
  }
}