import { render, staticRenderFns } from "./Appointment.vue?vue&type=template&id=368796a4&scoped=true"
import script from "./Appointment.vue?vue&type=script&lang=js"
export * from "./Appointment.vue?vue&type=script&lang=js"
import style0 from "./Appointment.vue?vue&type=style&index=0&id=368796a4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "368796a4",
  null
  
)

export default component.exports