import Request from "../../helper/Request";
export default {
    createSetupIntent(context, payload) {
        return Request({
            method: 'post',
            endpoint: 'client',
            data: payload,
            isDataRaw: true
        }).then(response => {
            let client = response.data
            client.editing = false
            context.commit('addClient', client)
            return response.data
        })
        .catch(error => {
        throw new Error(error.response.data.message)
        })
    }
}