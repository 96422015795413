import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=074f7a18&scoped=true"
import script from "./Notifications.vue?vue&type=script&lang=js"
export * from "./Notifications.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "074f7a18",
  null
  
)

export default component.exports