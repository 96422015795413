import { render, staticRenderFns } from "./Language.vue?vue&type=template&id=cb4e4a36&scoped=true"
import script from "./Language.vue?vue&type=script&lang=js"
export * from "./Language.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "cb4e4a36",
  null
  
)

export default component.exports