<template>
  <div id="container">
    <slot></slot>
  </div>
</template>

<script>
    export default {
        name: "guestAppointmentLayout"
    }
</script>

<style scoped>

</style>