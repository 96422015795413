<template>
    <div>
        <div class="p-4 text-xl w-full text-center bg-white">
            <header class="flex justify-between">
                <img src="https://app.digitaltolk.se/assets/images/brand-logo.svg" alt="DigitalTolk">
                <LocaleDropDown />
            </header>
        </div>
        <div class="mt-4 p-12 text-center" v-if="isSuccessful">
            <h2 class="text-2xl">{{ trans('appointment_created') }}</h2>
        </div>
        <div class="mt-4" v-if="isError">
            <h2 class="text-2xl text-red-700">{{ trans('error') }}</h2>
        </div>
    </div>
</template>
<script>
import { mapGetters } from 'vuex';
import LocaleDropDown from '@/views/guest/components/LocaleDropDown';
export default {
    components: {
        LocaleDropDown
    },
    data() {
        return {
            isSuccessful: false,
            isError: false
        }
    },
    mounted() {
        console.log('stripe return')
        this.initialize()
    },
    computed: {
        ...mapGetters({
            verbiage: 'verbiage/verbiage',
        })
    },
    methods: {
        trans(key) {
            if (this.verbiage && this.verbiage[key]) {
                return this.verbiage[key]
            }
            return key
        },
        async initialize() {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const sessionId = urlParams.get('session_id');
            const language = urlParams.get('language');
            const clientPhone = urlParams.get('client_phone');
            const bookingNumber = urlParams.get('booking_number');
            const datetime = urlParams.get('datetime');
            const createGuestAppointmentApi = process.env.VUE_APP_LARAVEL_API + '/stripe/guest/appointment'
            const response = await fetch(createGuestAppointmentApi, {
                headers: {
                Accept: "application/json",
                    "Content-Type": "application/json",
                },
                method: "POST",
                body: JSON.stringify({ 
                    session_id: sessionId,
                    language: language,
                    client_phone: clientPhone,
                    booking_number: bookingNumber,
                    datetime: datetime
                }),
            });
            const session = await response.json();

            if (session.status == 'open') {
                this.$router.replace({ name: 'guest_appointment' })
                
            } else if (session.status == 'complete') {
                this.isSuccessful = true
            }
            
        }
    }
}
</script>