<template>
    <div class="p-4 text-xl w-full text-center bg-white">
        <header class="flex justify-between">
            <img src="https://app.digitaltolk.se/assets/images/brand-logo.svg" alt="DigitalTolk">
            <LocaleDropDown />
        </header>
        <div>
            <GuestForm />
        </div>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
import LocaleDropDown from '@/views/guest/components/LocaleDropDown';
import GuestForm from './components/GuestForm'
export default {
    components: {
        LocaleDropDown,
        GuestForm
    },
    data() {
        return {
            locale: 'en'
        }
    },
    methods: {
        ...mapActions({
            loadLanguages: 'language/getLanguages'
        })
    },
    mounted() {
        this.loadLanguages()
    }
}
</script>