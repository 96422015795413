<template>
  <div :class="blockClass">
    <div id="checkout">
      
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  data() {
    return {
      blockClass: 'stripe-setup-form',
      elements: null,
      stripeInstance: null,
      error: '',
      disabled: false
    }
  },
  computed: {
    ...mapState('payment', ['stripe']),
    ...mapState('appointment', ['guestForm']),
  },
  mounted() {
    this.includeStripe('js.stripe.com/v3/', () => {
      this.configureStripe()
    })
  },
  methods: {
    configureStripe() {
      // This is your test publishable API key.
      this.stripeInstance = Stripe(this.stripe.public_key);
      this.initialize();
    },

    includeStripe(URL, callback) {
      let documentTag = document
      let tag = 'script'
      let object = documentTag.createElement(tag)
      let scriptTag = documentTag.getElementsByTagName(tag)[0]
      object.src = '//' + URL
      if (callback) {
        object.addEventListener(
          'load',
          () => {
            callback()
          },
          false
        )
      }
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    // Create a Checkout Session
    async initialize() {
      const fetchClientSecret = async () => {
        const data = this.guestForm;
        const checkoutSessionApi = process.env.VUE_APP_LARAVEL_API + '/stripe/checkout_session';
        const response = await fetch(checkoutSessionApi, {
          method: "POST",
          headers: {
            'Content-Type': 'application/json' // Set the content type to JSON
          },
          body: JSON.stringify(data)
        });
        const { clientSecret } = await response.json();
        return clientSecret;
      };

      const checkout = await this.stripeInstance.initEmbeddedCheckout({
        fetchClientSecret,
      });

      // Mount Checkout
      checkout.mount('#checkout');
    }
  }
}
</script>
<style>
.stripe-setup-form {
  #payment-form {
    padding: 0px;
    margin-right: 30px;
    .submit-btn {
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      text-transform: capitalize;
      cursor: pointer;
      margin-top: 10px;
      font-weight: 200;
    }
    .submit-btn:disabled {
      background: gray;
      border: gray;
      cursor: not-allowed;
    }
  }
}
</style>
