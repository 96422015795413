<template>
    <div class="border p-4 rounded-md shadow-sm bg-gray-100 mt-5 md:w-full lg:max-w-2xl mx-auto">
        <!-- appointment form -->
        <appointment-form 
            ref="currentComponent"
            v-if="steps[currentStep] == 'appointment'"
        />
        <!-- /appointment form -->
        <!-- stripe payment form  -->
        <payment-setup 
            ref="currentComponent"
            v-if="steps[currentStep] == 'payment'"
        />
        <!-- /stripe payment form -->

        <div v-if="currentStep == 0" class="text-left mt-4">
            <pink-button @click="next">{{ trans('next') }}</pink-button>
        </div>
        
    </div>
</template>
<script>
import { mapGetters } from 'vuex'
import PinkButton from '@/components/buttons/PinkButton'
import AppointmentForm from '@/views/guest/components/AppointmentForm'
import PaymentSetup from '@/views/payment/components/PaymentSetup'
export default {
    computed: {
        ...mapGetters({
            verbiage: 'verbiage/verbiage',
        })
    },
    components: {
        PinkButton,
        AppointmentForm,
        PaymentSetup
    },
    data() {
        return {
            currentStep: 0,
            steps: [
                'appointment',
                'payment'
            ]
        }
    },
    methods: {
        trans(key) {
            if (this.verbiage && this.verbiage[key]) {
                return this.verbiage[key]
            }
            return key
        },
        
        next() {
            if(this.$refs['currentComponent'].isValid()) {
                this.currentStep++;
            }
        }
    },
    mounted() {
        console.log('mounted')
    }
}
</script>