<template>
  <div id="appointment" class="m-auto mt-8 rounded w-full text-center">
    <vue-element-loading :active="show" spinner="ring" is-full-screen color="#38b2ac"/>
    <transition name="fade" v-if="suggested">
      <div class="bg-white m-auto max-w-md rounded shadow-lg p-2">
        <span class="text-gray-700 font-medium">
          {{ trans('aftercancelheading') }}
        </span>
      </div>
    </transition>
    <transition name="fade" v-if="suggestions && !suggested">
      <div class="bg-white m-auto max-w-2xl rounded shadow-lg p-2"
      >
        <div class="text-sm text-gray-700 mb-4">
          {{ trans('appointment_cancel_note') }}
        </div>

        <div class="mb-3">
          <datetime v-model="date1" 
                    type="datetime" 
                    class="theme-dt p-2"
                    value-zone="local"
                    :placeholder="
                    trans('suggestions_placeholder') + ' 1'"
                    format="yyyy-MM-dd HH:mm"
                    input-class="w-2/3 rounded text-gray-700 bg-gray-200 p-2"
                    :min-datetime="cachedDateTime.date1"
                    :phrases="{ok: trans('continue'), cancel: trans('exit')}"
          ></datetime>
        </div>
        <div class="mb-3">
          <datetime v-model="date2"
                    type="datetime"
                    class="theme-dt p-2"
                    value-zone="local"
                    :placeholder="trans('suggestions_placeholder') + ' 2'"
                    format="yyyy-MM-dd HH:mm"
                    input-class="w-2/3 rounded text-gray-700 bg-gray-200 p-2"
                    :min-datetime="cachedDateTime.date1"
                    :phrases="{ok: trans('continue'), cancel: trans('exit')}"
          ></datetime>
        </div>
        <div class="mb-3">
          <datetime v-model="date3"
                    type="datetime"
                    class="theme-dt p-2"
                    value-zone="local"
                    :placeholder="trans('suggestions_placeholder') + ' 3'"
                    format="yyyy-MM-dd HH:mm"
                    input-class="w-2/3 rounded text-gray-700 bg-gray-200 p-2"
                    :min-datetime="cachedDateTime.date1"
                    :phrases="{ok: trans('continue'), cancel: trans('exit')}"
          ></datetime>
        </div>
        <button
          class="outline-none focus:outline-none bg-teal-500 hover:bg-teal-600 text-white font-bold p-2 rounded"
          @click="suggestNewTimings"
        >
          {{ trans('suggest') }}
        </button>
      </div>
    </transition>
  </div>
</template>

<script>
  import Popup from '@/mixins/Popup'
  import {mapActions, mapGetters} from 'vuex'
  import VueElementLoading from 'vue-element-loading'

  import {Datetime} from 'vue-datetime'
  import 'vue-datetime/dist/vue-datetime.css'

  export default {
    name: "Suggestions",
    mixins: [Popup],
    components: {
      VueElementLoading,
      Datetime,
    },
    computed: {
      ...mapGetters({
        user: 'auth/user'
      }),
    },
    data() {
      return {
        suggested: false,
        error: false,
        show: false,
        timeRegex: /\d{2}:\d{2}/i,
        suggestions: false,
        showSuggestions: false,
        cachedDateTime: {
          date1: null,
          time1: null,
          date2: null,
          time2: null,
          date3: null,
          time3: null,
        },
        date1: null,
        time1: null,
        date2: null,
        time2: null,
        date3: null,
        time3: null,
        verbiageData: {}
      }
    },
    created() {
      let currentDate = new Date(new Date().getTime() + 24 * 60 * 60 * 1000);
      let day = currentDate.getDate() >= 10 ? currentDate.getDate() : '0' + currentDate.getDate()
      let month = currentDate.getMonth() + 1 >= 10 ? currentDate.getMonth() + 1 : '0' + currentDate.getMonth() + 1
      let year = currentDate.getFullYear()
      this.cachedDateTime.date1 = this.cachedDateTime.date2 = this.cachedDateTime.date3 = `${year}-${month}-${day}T00:00:00.000Z`
      this.get({
        id: this.$route.params.id
      }).then(response => {
        this.suggestions = response.data.suggestions
        this.verbiageData = response.data.verbiage

        if (!this.suggestions) {
          this.$router.push({name: 'NotFound'})
        }
        if (typeof response.status !== 'undefined' && response.status == 'fail') {
          this.suggested = true
        } else {
          this.suggested = false
        }
      }).catch((error) => {
        console.error('error', error)
        // this.$router.push({name: 'NotFound'})
      })
    },
    
    methods: {
      ...mapActions('appointment', [
        'get',
        'suggestTime'
      ]),
      ...mapActions({
        getVerbiage: 'verbiage/loadVerbiage',
      }),
      

      trans(key) {
        if ( this.verbiageData && this.verbiageData[key] ) {
          return this.verbiageData[key];
        } else {
          return key;
        }
      },

      suggestNewTimings() {
        if (!this.date1) {
          this.popup(this.trans('fill_atleast_first_date'), 'error', 3000)
          return
        }

        if (this.error) {
          this.popup('Please input valid time. Format(24 hours): HH:MM', 'error', 3000)
          return
        }
        this.show = true
        let date1 = this.date1.split('T')
        let time1 = this.timeRegex.exec(date1[1])[0]
        date1 = date1[0]
        let date2 = null
        let time2 = null        
        let date3 = null
        let time3 = null
        if(this.date2 != '') {
          date2 = this.date2.split('T')
          time2 = this.timeRegex.exec(date2[1])[0]
          date2 = date2[0]
        }
        if(this.date3 != '') {
          date3 = this.date3.split('T')
          time3 = this.timeRegex.exec(date3[1])[0]
          date3 = date3[0]
        }
        this.suggestTime({
          id: this.$route.params.id,
          cancelled_by: 'client',
          date1: date1,
          time1: time1,
          date2: date2,
          time2: time2,
          date3: date3,
          time3: time3,
          user:  this.user
        }).then(() => {
          this.show = false
          this.suggested = true
          this.showSuggestions = false
          this.popup(this.trans('suggested_new_timings'), 'success', 5000)
        }).catch(() => {
          this.show = false
          this.popup(this.trans('unable_to_suggest_new_timings'), 'error', 3000)
        })
      },
    },
    mounted(){
      let locale = null
      let host = window.location.host
      if(host.indexOf('user') >= 0 || host.indexOf('localhost') >= 0) {
        locale = 'se'
      }else if(host.indexOf('english') >= 0) {
        locale = 'en'
      } else if(host.indexOf('svenska') >= 0) {
        locale = 'se'
      }
      
      this.getVerbiage({
        languageCode: locale || 'se'
      })
    }
  }
</script>

<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */
  {
    opacity: 0;
  }

  header {
    display: none !important;
  }

  header::before {
    display: none !important;
  }

  .h-22 {
    height: 84px;
  }

  .w-brief {
    width: 480px;
  }

  .p-1-5 {
    padding: .35rem;
  }
</style>