<template>
    <div>
        <h2 class="text-3xl border-b-4 rounded-sm border-pink-400 pb-2 mb-6 shadow-lg text-left pl-4">
            {{ trans('create_appointment') }}
        </h2>

        <div class="grid grid-cols-1 sm:grid-cols-2 gap-4">
            <!-- language -->
            <div class="text-left">
                <label for="language" class="text-md">{{ trans('language') }}</label>
                <select 
                    id="language" 
                    v-model="form.language" 
                    class="w-full border p-2 mt-1 rounded"
                >
                    <option :key="lang.language_code" v-for="lang in languages" :value="lang.language_code">
                        {{ lang.language }}
                    </option>
                </select>
                <div class="text-red-500">{{ this.error.language }}</div>
            </div>
            <!-- /language -->

            <!-- client phone -->
            <div class="text-left">
                <label for="client_phone" class="text-md">{{ trans('client_phone') }}</label>
                <input 
                    type="text"
                    id="client_phone" 
                    v-model="form.client_phone" 
                    class="w-full border p-2 mt-1 rounded"
                >
                <div class="text-red-500">{{ this.error.client_phone }}</div>
            </div>
            <!-- /client phone -->

            <!-- booking number -->
            <div class="text-left">
                <label for="booking_number" class="text-md">{{ trans('booking_number') }}</label>
                <input 
                    type="text"
                    id="booking_number" 
                    v-model="form.booking_number" 
                    class="w-full border p-2 mt-1 rounded"
                    placeholder="# 123123"
                >
                <div class="text-red-500">{{ this.error.booking_number }}</div>
            </div>
            <!-- /booking number -->

            <!-- facility -->
            <div class="text-left">
                <label for="facility" class="text-md">{{ trans('facility') }}</label>
                <input 
                    type="text"
                    id="facility" 
                    v-model="form.facility" 
                    class="w-full border p-2 mt-1 rounded"
                    placeholder=""
                >
                <div class="text-red-500">{{ this.error.facility }}</div>
            </div>
            <!-- /facility -->

            <!-- appointment date time -->
            <div class="text-left">
                <label for="datetime" class="text-md">
                    {{ trans('appointment_date_time') }}
                </label>
                <datetime 
                    type="datetime"
                    id="datetime" 
                    v-model="form.datetime" 
                    input-class="w-full p-2 mt-1 rounded bg-white border"
                />
                <div class="text-red-500">{{ this.error.datetime }}</div>
            </div>
            <!-- /appointment date -->
        </div>
    </div>
</template>
<script>
import { mapGetters, mapState } from 'vuex'
import { Datetime } from 'vue-datetime'
export default {
    computed: {
        ...mapState('appointment', {
            form: 'guestForm'
        }),
        ...mapGetters({
            verbiage: 'verbiage/verbiage',
            languages: 'language/getLanguages'
        })
    },
    components: {
        Datetime
    },
    data() {
        return {
            error: {
                language: '',
                client_phone: '',
                booking_number: '',
                datetime: ''
            }
        }
    },
    methods: {
        trans(key) {
            if (this.verbiage && this.verbiage[key]) {
                return this.verbiage[key]
            }
            return key
        },
        clearErrors() {
            this.error = {
                language: '',
                client_phone: '',
                booking_number: '',
                datetime: ''
            }
        },
        isValid() {
            let errorsFound = false
            this.clearErrors();
            if(this.form.language == '') {
                this.error.language = this.trans('language_required')
                errorsFound = true
            }
            if(this.form.client_phone == '') {
                this.error.client_phone = this.trans('client_phone_required')
                errorsFound = true
            }
            if(this.form.booking_number == '') {
                this.error.booking_number = this.trans('booking_number_required')
                errorsFound = true
            }
            if(this.form.facility == '') {
                this.error.facility = this.trans('facility_required')
                errorsFound = true
            }
            if(this.form.datetime == '') {
                this.error.datetime = this.trans('datetime_required')
                errorsFound = true
            }
            return !errorsFound
        },
    }
}
</script>