<template>
    <div class="h-12 w-20 text-right">
        <div    
            @click="toggleDropDown"
        >
            <i class="fa fa-flag hover:text-pink-500"></i>
        </div>
        <transition name="fade">
            <div v-if="open" class="border border-gray-300 text-center rounded">
                <div
                    class="hover:text-pink-600 hover:bg-gray-100 border cursor-pointer px-3 py-1"
                    @click="loadVerbiage('en')"
                >
                    <i v-if="locale == 'en'" class="fa fa-check"></i>
                    EN
                </div>
                <div 
                    class="hover:text-pink-600 hover:bg-gray-100 border cursor-pointer px-3 py-1"
                    @click="loadVerbiage('se')"
                >
                    <i v-if="locale == 'se'" class="fa fa-check"></i>
                    SE
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    data() {
        return {
            locale: 'en',
            open: false
        }
    },
    computed: {
        ...mapGetters({
            translations: 'verbiage/verbiage'
        })
    },
    watch: {
        translations(newVal) {
            console.log('locale-change', newVal)
        }
    },
    methods: {
        ...mapActions({
            verbiage: 'verbiage/loadVerbiage',
        }),
        toggleDropDown() {
            this.open = !this.open
        },
        loadVerbiage(locale) {
            this.locale = locale
            this.open = false
            this.verbiage({
                languageCode: locale
            })
        }
    },
    mounted() {
        this.loadVerbiage(this.locale)
    }
}
</script>