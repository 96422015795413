<template>
  <div :class="blockClass">
    <stripe-setup-form />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import StripeSetupForm from './StripeSetupForm'
export default {
  components: {
    'stripe-setup-form': StripeSetupForm
  },
  data() {
    return {
      blockClass: 'payment-setup',
      isSetupInProgress: true
    }
  },
  computed: {
    ...mapState('payments', ['provider'])
  },
  methods: {
    setupComplete() {
      this.isSetupInProgress = false
    },
    isValid() {
      this.$refs[this.provider + '-setup-form'].isFormValid() // to show the error messages.
      return !this.isSetupInProgress
    }
  }
}
</script>

<style>
.payment-setup {
  .flex-align-center {
    display: flex;
    align-items: center;
    gap: 15px;
  }
  .provider-icon {
    font-size: 40px;
  }
  .setup-complete-message {
    padding: 10px;
    margin-right: 20px;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    border-radius: 5px;
    margin-top: 20px;
    font-size: 18px;
    text-align: center;
  }
}
</style>
